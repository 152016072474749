const SPACE_DATA = {
		"u6987026":  [{space_js: "//ccddbd.hanganan.cn/site/ql_k/common/tn/mra_k.js", space_src: "baidujs", space_type: "inters", space_id: "u6987026"}],
		"u6987028":  [{space_js: "//ccddbd.hanganan.cn/common/s/static/n-mvpot/cp.js", space_src: "baidujs", space_type: "native", space_id: "u6987028"}],
		"u6987027":  [{space_js: "//ccddbd.hanganan.cn/production/rmlu/source/o_ns_b/n.js", space_src: "baidujs", space_type: "native", space_id: "u6987027"}],
		"u6987029":  [{space_js: "//ccddbd.hanganan.cn/production/ton/wq/openjs/pu-d/resource/w.js", space_src: "baidujs", space_type: "native", space_id: "u6987029"}],
		"u6987030":  [{space_js: "//ccddbd.hanganan.cn/site/lgf_oi/h/source/my/m.js", space_src: "baidujs", space_type: "native", space_id: "u6987030"}],
		"u6987177":  [{space_js: "//ccddbd.hanganan.cn/common/xsr-a/static/utbt/t.js", space_src: "baidujs", space_type: "inters", space_id: "u6987177"}],
		"u6987178":  [{space_js: "//ccddbd.hanganan.cn/source/y-tsbvu/cu/resource/v.js", space_src: "baidujs", space_type: "native", space_id: "u6987178"}],
		"u6987179":  [{space_js: "//ccddbd.hanganan.cn/site/avud_x_we_wd.js", space_src: "baidujs", space_type: "native", space_id: "u6987179"}],
		"u6987180":  [{space_js: "//ccddbd.hanganan.cn/production/rm/openjs/l-u/onvos.js", space_src: "baidujs", space_type: "native", space_id: "u6987180"}],
		"u6987181":  [{space_js: "//ccddbd.hanganan.cn/site/sn_mvp/common/ow/p_w.js", space_src: "baidujs", space_type: "native", space_id: "u6987181"}],
};
export const SPACE_MAP = {
	Home_inter:  SPACE_DATA["u6987026"],
	Home_native:  SPACE_DATA["u6987028"],
	Home_banner:  SPACE_DATA["u6987027"],
	Detail_inter:  SPACE_DATA["u6987026"],
	Detail_native:  SPACE_DATA["u6987028"],
	Detail_banner:  SPACE_DATA["u6987027"],
	List_native:  SPACE_DATA["u6987028"],
	List_banner:  SPACE_DATA["u6987027"],
	Result_inter:  SPACE_DATA["u6987026"],
	Result_banner:  SPACE_DATA["u6987027"],
	Result_native:  SPACE_DATA["u6987028"],
	Result_native_center1:  SPACE_DATA["u6987029"],
	Result_native_center2:  SPACE_DATA["u6987030"],
}
export const ALL_SPACE_MAP = {
    HNW_HRBZ_VO_1 : {
		Home_inter:  SPACE_DATA["u6987026"],
		Home_native:  SPACE_DATA["u6987028"],
		Home_banner:  SPACE_DATA["u6987027"],
		Detail_inter:  SPACE_DATA["u6987026"],
		Detail_native:  SPACE_DATA["u6987028"],
		Detail_banner:  SPACE_DATA["u6987027"],
		List_native:  SPACE_DATA["u6987028"],
		List_banner:  SPACE_DATA["u6987027"],
		Result_inter:  SPACE_DATA["u6987026"],
		Result_banner:  SPACE_DATA["u6987027"],
		Result_native:  SPACE_DATA["u6987028"],
		Result_native_center1:  SPACE_DATA["u6987029"],
		Result_native_center2:  SPACE_DATA["u6987030"],
    },
    HNW_HRBZ_VO_2 : {
		Home_inter:  SPACE_DATA["u6987177"],
		Home_banner:  SPACE_DATA["u6987178"],
		Home_native:  SPACE_DATA["u6987179"],
		Detail_inter:  SPACE_DATA["u6987177"],
		Detail_banner:  SPACE_DATA["u6987178"],
		Detail_native:  SPACE_DATA["u6987179"],
		List_banner:  SPACE_DATA["u6987178"],
		List_native:  SPACE_DATA["u6987179"],
		Result_inter:  SPACE_DATA["u6987177"],
		Result_banner:  SPACE_DATA["u6987178"],
		Result_native:  SPACE_DATA["u6987179"],
		Result_native_center1:  SPACE_DATA["u6987180"],
		Result_native_center2:  SPACE_DATA["u6987181"],
    },
    DEFAULT : {
		Home_inter:  SPACE_DATA["u6987026"],
		Home_native:  SPACE_DATA["u6987028"],
		Home_banner:  SPACE_DATA["u6987027"],
		Detail_inter:  SPACE_DATA["u6987026"],
		Detail_native:  SPACE_DATA["u6987028"],
		Detail_banner:  SPACE_DATA["u6987027"],
		List_native:  SPACE_DATA["u6987028"],
		List_banner:  SPACE_DATA["u6987027"],
		Result_inter:  SPACE_DATA["u6987026"],
		Result_banner:  SPACE_DATA["u6987027"],
		Result_native:  SPACE_DATA["u6987028"],
		Result_native_center1:  SPACE_DATA["u6987029"],
		Result_native_center2:  SPACE_DATA["u6987030"],
    },
};
